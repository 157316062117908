import React, { useState } from 'react';

const Home = () => {
	const [addr_1, setAddr_1] = useState('');
	const [addr_2, setAddr_2] = useState('');
	const [status, setStatus] = useState('');

	const appVersion = "ver. 0.01.15b";

	const whitelistsAddr = [

		["HEN[Binance]-OLD", "BEP20", "USDT", "0xc39c0494337900b70e1701175bbf4622f95f7a19"],
		["HEN[B-Wallet]", "BEP20", "BNB", "0x63Bf0E6C166b377c19Dc4126acA428Fd95c37006"],
		["HEN[Binance]-OLD", "TRC20", "USDT", "TGLSokWa9nyeLDH2YLdxHzDfaMRenfnCno"],
		["BUN[Indodax]", "TRC20", "USDT", "TCYrjaRnz2pPjS5S1kQ9pFc19q5yxTgfsn"],
		["BUN[Binance]", "TRC20", "USDT", "TTYGtDRNxQBghGCFCmNf69j7fEXUeFnjTB"],
		["HEN[Binance]", "BEP20", "USDT", "0x1a527463814ffc8afaef3184b3b6fea40cdadb8e"],
		["HEN[Binance]", "TRC20", "USDT", "TN9KTo6MT3yfPAW6DXd5FC2mVQFUC1AC6a"],
		["HEN[Binance]", "ERC20", "USDT", "0x1a527463814ffc8afaef3184b3b6fea40cdadb8e"],
	];

	const CheckAddress = () => {
		if (addr_1 === '') return;
		console.log(whitelistsAddr.length);
		for (let i = 0; i < whitelistsAddr.length; i++) {
			if (addr_1 === whitelistsAddr[i][3]) {
				setStatus("✅");
				//console.log("Found");
				setAddr_2(whitelistsAddr[i]);
				return;
			}
		}
		setStatus("❌");
	};

	const ClearAll = () => {
		setAddr_1('');
		setAddr_2('');
		setStatus('');
	};

	return (
		<>
			<h1>CHECK ADDRESS</h1>
			<p>{appVersion}</p>
			<div className="Input-Frame">
				<table>
					<thead>
						<tr>
							<th>
								<label></label>
							</th>
							<th>
								<label>Address</label>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{status}</td>
							<td><input type="text" name="addr1" value={addr_1} autoComplete="off" spellCheck="false" onChange={
								(e) => setAddr_1(e.target.value)
							}></input></td>
						</tr>
						<tr>
							<td>

							</td>
							<td>
								<button onClick={CheckAddress}>Check</button>
								<button onClick={ClearAll}>Clear</button>
							</td>
						</tr>
					</tbody>
				</table>
				<div className='Details-Div'>
					{addr_2[0]}-{addr_2[1]}-{addr_2[2]}
				</div>
			</div>
		</>
	);

}

export default Home;